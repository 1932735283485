import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import Image from "next/image";
import router from "next/router";
import { ArrowCircleLeft } from "phosphor-react";
import Button from "../components/interface/button";
// Images
import semIcon from "../public/assets/brand/sem-icon.svg";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

const Custom404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>404 - SEM</title>
      </Head>
      <div
        className={`h-screen w-screen flex flex-col items-center justify-center
    `}
      >
        <span className={`flex flex-col items-center justify-center space-y-6`}>
          <h1 className={`text-2xl text-brand-500 font-bold tracking-wide`}>
            404 - {t("errors.404")}
          </h1>
          <Button
            aria-label={t("common:buttons.back")}
            label={t("common:buttons.back")}
            icon={<ArrowCircleLeft weight={`regular`} />}
            tint={`dark`}
            onClick={() => {
              router.back();
            }}
          />
        </span>

        <div className={"fixed bottom-4 inset-x-0  whitespace-nowrap"}>
          <div className={`flex items-center justify-center py-2 space-x-4`}>
            <span className={`relative h-10 w-10`}>
              <Image
                alt={"SEM logo"}
                src={semIcon}
                layout={"fill"}
                objectFit={`contain`}
                priority
              />
            </span>
            <span className={`text-base text-gray-900 font-bold mt-0.5`}>
              SEM FOR BUSINESS
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Custom404;
